/*


    font-family: 'Open Sans', sans-serif;

    font-family: 'Montserrat', sans-serif;

    font-family: 'Pacifico', cursive;


*/
.heading__h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 38px;
    color: #F75E71;
    @media only screen and (max-width: 767px) {
        padding-bottom: 30px;
    }
    @media only screen and (min-width: 768px) {
        padding-bottom: 60px;
    }
    text-transform: uppercase;
}
.heading__h4 {
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    color: #F75E71;
    //padding-bottom: 60px;
    text-transform: uppercase;
}
.padding-botTXT {
    padding-bottom: 30px;
}
.content__p {
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    color: #707070;
}
.p__bold {
    font-size: 28px;
    font-weight: bold;
}
.pad__top {
    @media only screen and (max-width: 1367px) {
        padding-top: 60px;
    }
    @media only screen and (min-width: 1368px) {
        padding-top: 125px;
    }

}
.padding-bot-h3--footer {
    font-family: 'Montserrat', sans-serif;
    font-size: 28px;
}
.footer__p {
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
}
nav a {
    
}
.MSW-credits{
    &__h4--fs {
        font-size: 20px;
    }
    &__p--fs {
        font-size: 16px;
    }
}
.MSF-technologies__fs {
    font-size: 18px !important;
    margin-bottom: 0!important;
}
.MSF-technologies__h4 {
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    color: #F75E71;
}