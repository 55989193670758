

/*#galleryIMG-bg {
    grid-column: marginLeft-start / marginRight-end;
    grid-row: galleryIMG-start / galleryIMG-end;
    @include respond(small-phone) {
        @include gallery_imgs_phone; 
    } 
    @include respond(medium-phone) { 
        @include gallery_imgs_phone; 
    } 
    @include respond(large-phone) {
        @include gallery_imgs_phone; 
    } 
    @include respond(tablet) {
        --background-img-gallery: url(/img/desktopHD/munchkin_softworks-website-our_work.jpg); 
    } 
    @include respond(screen-HD) {
        --background-img-gallery: url(/img/desktopHD/munchkin_softworks-website-our_work.jpg); 
    } 
    @include respond(screen-2k) {
        --background-img-gallery: url(/img/img-2k/munchkin_softworks-website-our_work.jpg); 
    }
    @include respond(screen-4k) {
        --background-img-gallery: url(/img/img-4k/munchkin_softworks-website-our_work.jpg); 
    }  
    background-image: var(--background-img-gallery);
}

#galleryVeil { 
    grid-column: marginLeft-start / marginRight-end;
    grid-row: galleryIMG-start / galleryIMG-end;
}
#galleryTXT-bg {
    grid-column: marginLeft-start / marginRight-end;
    grid-row: galleryTXT-start / galleryTXT-end;
}
*/
.gallery {
    //grid-column: marginLeft-start / marginRight-end;
    //grid-row: galleryTXT-start / galleryTXT-end;  
    //justify-content: center;
    overflow: hidden;
    &__heading {
        text-align: center;
    }
    &__side-title-wrapper { 
        display: grid;
        @media only screen and (max-width: 767px) {
            grid-template-columns: 100vw;
            justify-items: center;
        }
        @media only screen and (min-width: 768px) and (max-width: 1365px) {
            grid-template-columns: 100vw;
            justify-items: center;
        }
        @media only screen and (min-width: 1366px) {
            grid-template-columns: 1fr [text-start] calc(1366px - 140px) [text-end] 1fr;
            justify-items: start;
        }                       
        @media only screen and (min-width: 1920px) {
            grid-template-columns: 1fr [text-start] calc(1920px - 250px) [text-end] 1fr;
            justify-items: start;
        }

        align-items: end;
        &--h3{
            @media only screen and (max-width: 767px) {
                grid-column: 1 / 2;
            }
            @media only screen and (min-width: 768px) and (max-width: 1365px) {
                grid-column: 1 / 2;
            }
            @media only screen and (min-width: 1366px) {
                grid-column: 2 / 3;
                padding-left: 70px;
            }                       
            @media only screen and (min-width: 1920px) {
                grid-column: 2 / 3;
                padding-left: 125px;
            }
        }
    }
    &__side-title {
        grid-column: text-start / text-end;
        text-align: center;
        @media only screen and (max-width: 1364px) {
            padding-left: 1.5rem;
            padding-top: 4rem;
        }
    }
    &__wrapper {
        height: auto;
        overflow-x: hidden;
        overflow-y: visible;
        padding-top: 5rem;

        &--websites {
            display: grid;
            @media only screen and (max-width: 767px) {
                grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
                grid-row-gap: 40px;
            }
            @media only screen and (min-width: 768px) and (max-width: 1364px) {
                grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
                grid-row-gap: 40px;
            }
            @media only screen and (min-width: 1366px) {
                                        //start name                                                //end name  /  start 
                grid-template-columns:  [borderLeft-start]      1fr                               [borderLeft-end GAL_col1-start]
                                                                calc((1366px - 280px) * 0.382)    [GAL_col1-end GAL_col2-start]
                                                                calc((1366px - 280px) * 0.236)    [GAL_col2-end GAL_col3-start]
                                                                calc((1366px - 280px) * 0.382)    [GAL_col3-end borderRight-start]
                                                                1fr                               [borderRight-end];
                
                grid-template-rows:     [GAL_project1-start]    min-content     [GAL_project1-end GAL_project2-start]
                                                                min-content     [GAL_project2-end GAL_project3-start] 
                                                                min-content     [GAL_project3-end GAL_project4-start]
                                                                min-content     [GAL_project4-end GAL_project5-start]
                                                                min-content     [GAL_project5-end GAL_project6-start];
                grid-row-gap: 140px;
            }                       
            @media only screen and (min-width: 1920px) {
                                        //start name                                                //end name  /  start 
                grid-template-columns:  [borderLeft-start]      1fr                               [borderLeft-end GAL_col1-start]
                                                                calc((1920px - 500px) * 0.382)    [GAL_col1-end GAL_col2-start]
                                                                calc((1920px - 500px) * 0.236)    [GAL_col2-end GAL_col3-start]
                                                                calc((1920px - 500px) * 0.382)    [GAL_col3-end borderRight-start]
                                                                1fr                               [borderRight-end];
                
                grid-template-rows:     [GAL_project1-start]    min-content     [GAL_project1-end GAL_project2-start]
                                                                min-content     [GAL_project2-end GAL_project3-start] 
                                                                min-content     [GAL_project3-end GAL_project4-start]
                                                                min-content     [GAL_project4-end GAL_project5-start]
                                                                min-content     [GAL_project5-end GAL_project6-start];
                grid-row-gap: 250px;
            }
        }
        &--games {
            display: grid;
            @media only screen and (max-width: 767px) {
                grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
                grid-row-gap: 40px;
            }
            @media only screen and (min-width: 768px) and (max-width: 1364px) {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
                grid-row-gap: 40px;
                padding-bottom: 60px;
            }
            @media only screen and (min-width: 1366px) {
                                    //start name                                                    //end name  /  start 
            grid-template-columns:  [borderLeft-start]      1fr                               [borderLeft-end GAL_col1-start]
                                                            calc((1366px - 280px) * 0.382)    [GAL_col1-end GAL_col2-start]
                                                            calc((1366px - 280px) * 0.236)    [GAL_col2-end GAL_col3-start]
                                                            calc((1366px - 280px) * 0.382)    [GAL_col3-end borderRight-start]
                                                            1fr                               [borderRight-end];
            
            grid-template-rows:     [GAL_project1-start]    min-content     [GAL_project1-end GAL_project2-start]
                                                            min-content     [GAL_project2-end GAL_project3-start];
            grid-row-gap: 140px;
            padding-bottom: 125px;
            }
            @media only screen and (min-width: 1920px) {
                                    //start name                                                    //end name  /  start 
            grid-template-columns:  [borderLeft-start]      1fr                               [borderLeft-end GAL_col1-start]
                                                            calc((1920px - 500px) * 0.382)    [GAL_col1-end GAL_col2-start]
                                                            calc((1920px - 500px) * 0.236)    [GAL_col2-end GAL_col3-start]
                                                            calc((1920px - 500px) * 0.382)    [GAL_col3-end borderRight-start]
                                                            1fr                               [borderRight-end];
            
            grid-template-rows:     [GAL_project1-start]    min-content     [GAL_project1-end GAL_project2-start]
                                                            min-content     [GAL_project2-end GAL_project3-start];
            grid-row-gap: 250px;
            padding-bottom: 125px;
            }

        }
        &--other {
            display: grid;
            @media only screen and (max-width: 767px) {
                grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
                grid-row-gap: 40px;
            }
            @media only screen and (min-width: 768px) and (max-width: 1364px) {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
                grid-row-gap: 40px;
                padding-bottom: 60px;
            }
            @media only screen and (min-width: 1366px) {
                                    //start name                                                    //end name  /  start 
            grid-template-columns:  [borderLeft-start]      1fr                               [borderLeft-end GAL_col1-start]
                                                            calc((1366px - 280px) * 0.382)    [GAL_col1-end GAL_col2-start]
                                                            calc((1366px - 280px) * 0.236)    [GAL_col2-end GAL_col3-start]
                                                            calc((1366px - 280px) * 0.382)    [GAL_col3-end borderRight-start]
                                                            1fr                               [borderRight-end];
            
            grid-template-rows:     [GAL_project1-start]    min-content [GAL_project1-end GAL_project2-start];
            grid-row-gap: 140px;
            padding-bottom: 125px;
            }
            @media only screen and (min-width: 1920px) {
                                    //start name                                                    //end name  /  start 
            grid-template-columns:  [borderLeft-start]      1fr                               [borderLeft-end GAL_col1-start]
                                                            calc((1920px - 500px) * 0.382)    [GAL_col1-end GAL_col2-start]
                                                            calc((1920px - 500px) * 0.236)    [GAL_col2-end GAL_col3-start]
                                                            calc((1920px - 500px) * 0.382)    [GAL_col3-end borderRight-start]
                                                            1fr                               [borderRight-end];
            
            grid-template-rows:     [GAL_project1-start]    min-content [GAL_project1-end GAL_project2-start];
            grid-row-gap: 250px;
            padding-bottom: 125px;
            }
        }
    }

    &__description {

        &--right {
            align-self: top;
            @media only screen and (max-width: 767px) {
                justify-self: center;
                max-width: 340px;
                text-align: center;
                padding-bottom: 30px;
                padding-left: 0px;
            }
            @media only screen and (min-width: 768px) and (max-width: 1365px) {
                justify-self: center;
                max-width: 600px;
                text-align: center;
                padding-right: 30px;
                padding-left: 30px;
                padding-bottom: 30px;
            }
            @media only screen and (min-width: 1366px) {
                justify-self: right;
                text-align: right;
                padding-left: 60px;
            } 
        }
        &--left {
            align-self: top;
            @media only screen and (max-width: 767px) {
                justify-self: center;
                max-width: 340px;
                text-align: center;
                padding-bottom: 30px;
                padding-right: 0px;
            }
            @media only screen and (min-width: 768px) and (max-width: 1365px) {
                justify-self: center;
                max-width: 600px;
                text-align: center;
                padding-right: 30px;
                padding-left: 30px;
                padding-bottom: 30px;
            }
            @media only screen and (min-width: 1366px) {
                justify-self: left;
                padding-right: 60px;
            } 
        }
        &--h4-right{
            text-align: right;
            text-transform: none;
            padding-bottom: 30px;
            @media only screen and (max-width: 1365px) {
                text-align: center;
            }
        }
        &--h4-left{
            text-align: left;
            text-transform: none;
            padding-bottom: 30px;
            @media only screen and (max-width: 1365px) {
                text-align: center;
            }
        }
        &--p-right {
            text-align: right;
            padding-bottom: 30px;
            @media only screen and (max-width: 1365px) {
                text-align: left;
            }
        }
        &--p-left {
            text-align: left;
            padding-bottom: 30px;
            @media only screen and (max-width: 1365px) {
                text-align: left;
            }
        }

    }
    &__view-all-button--wrapper {
        padding-top: 8rem;
        @media only screen and (max-width: 1365px) {
            padding-top: 4rem;
        }
        @media only screen and (max-width: 767px) {
            padding-top: 20px;
        }
    } 
}
.btn__portfolio {
    margin-top:30px;
}
@media only screen and (min-width: 1366px) {
    #project-1--preview {
        grid-column: GAL_col1-start / GAL_col2-end;
        grid-row: GAL_project1-start / GAL_project1-end;
    }
    #project-1--description {
        grid-column: GAL_col3-start / GAL_col3-end;
        grid-row: GAL_project1-start / GAL_project1-end;
    }
    #project-2--preview {
        grid-column: GAL_col2-start / GAL_col3-end;
        grid-row: GAL_project2-start / GAL_project2-end;
    }
    #project-2--description {
        grid-column: GAL_col1-start / GAL_col1-end;
        grid-row: GAL_project2-start / GAL_project2-end;
    }
    #project-3--preview {
        grid-column: GAL_col1-start / GAL_col2-end;
        grid-row: GAL_project3-start / GAL_project3-end;
    }
    #project-3--description {
        grid-column: GAL_col3-start / GAL_col3-end;
        grid-row: GAL_project3-start / GAL_project3-end;
    }
    #project-4--preview {
        grid-column: GAL_col2-start / GAL_col3-end;
        grid-row: GAL_project4-start / GAL_project4-end;
    }
    #project-4--description {
        grid-column: GAL_col1-start / GAL_col1-end;
        grid-row: GAL_project4-start / GAL_project4-end;
    }
    #project-5--preview {
        grid-column: GAL_col1-start / GAL_col2-end;
        grid-row: GAL_project5-start / GAL_project5-end;
    }
    #project-5--description {
        grid-column: GAL_col3-start / GAL_col3-end;
        grid-row: GAL_project5-start / GAL_project5-end;
    }
    #project-6--preview {
        grid-column: GAL_col2-start / GAL_col3-end;
        grid-row: GAL_project6-start / GAL_project6-end;
    }
    #project-6--description {
        grid-column: GAL_col1-start / GAL_col1-end; 
        grid-row: GAL_project6-start / GAL_project6-end;
    }
}