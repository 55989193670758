@use "../_0-pre-code/variables" as *;
/////////////////////New Code for BS5
.hero_IMG {
    //height: 75%;
    height: auto;
    max-height: 1200px;
    width: 100%;
    opacity: .15;
    position: absolute;
    z-index: -1;
    top: 200px;
    //@include media-breakpoint-up(sm) { ... }
    @media (min-width: 576px) {
        top: -130px;
        height: auto;
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
    //@include media-breakpoint-up(md) { ... }
    @media (min-width: 768px) {
        max-height: 1200px;
        width: auto;
        max-width: 100%;
    }
    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
        max-height: 1000px;
    }
    // X-Large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) {
        max-height: 1100px;
    }
}


















/*#header-bg {
    grid-column: 1 / -1;
    grid-row: paddTop-start / paddBot-end;

    @include respond(small-phone) {
        @include header_imgs_phone; 
    }
    @include respond(medium-phone) {
        @include header_imgs_phone; 
    }
    @include respond(large-phone) {
        @include header_imgs_phone; 
    }
    @include respond(tablet){
        background-image: $veil-header-dark, url("/img/Tablet/cover.jpg");
    }
    @include respond(screen-HD){
        background-image: $veil-header, url("/img/Desktop/munchkin_softworks-website-cover.jpg");
    }
    @include respond(screen-2k){
        background-image: $veil-header, url("/img/img-2k/cover-2k.jpg");
    }
    @include respond(screen-4k){
        background-image: $veil-header, url("/img/img-4k/cover.jpg");
    }
}
*/
.heading {
    &__grid {
        position: relative;
        // @include respond(small-phone) {
        //     --navBar-height: 54px;
        // } 
        // @include respond(medium-phone) {
        //     --navBar-height: 54px;
        // } 
        // @include respond(large-phone) {
        //     --navBar-height: 64px;
        // } 
        @media only screen and (max-width: 767px) {
            height: calc(135vh - 77px);
        }    
        @media only screen and (min-width: 768px) {
            height: calc(100vh - 120px);
        } 
        @media only screen and (min-width: 1366px) {
            height: calc(100vh - 160px);
        }                       
        @media only screen and (min-width: 1920px) {
            height: calc(100vh - 180px);
        }
        width: 100%;
        display: grid;  
        //grid-template-columns: 1fr calc((1920px - 250px) * 0.618) calc((1920px - 250px) * 0.382) 1fr;
        @media only screen and (max-width: 767px) {
            grid-template-columns:  
            //start name                                        //end name  /  start 
            [paddLeft-start]    1fr                             [paddLeft-end content-start]
                                calc(360px - 40px)              [content-end paddRight-start]
                                1fr                             [paddRight-end];
        }  
        @media only screen and (min-width: 768px) {
            grid-template-columns:  
            //start name                                        //end name  /  start 
            [paddLeft-start]    1fr                             [paddLeft-end content-start]
                                calc(768px - 60px)              [content-end paddRight-start]
                                1fr                             [paddRight-end];
        }  
        @media only screen and (min-width: 1366px) {
            grid-template-columns:  
            //start name                                        //end name  /  start 
            [paddLeft-start]    1fr                             [paddLeft-end content-start]
                                calc((1366px - 140px) * 0.46)   [content-end heroIMG-start]
                                calc((1366px - 140px) * 0.54)   [heroIMG-end paddRight-start]
                                1fr                             [paddRight-end];
        }                       
        @media only screen and (min-width: 1920px) {
            grid-template-columns:  
            //start name                                        //end name  /  start 
            [paddLeft-start]    1fr                             [paddLeft-end content-start]
                                calc((1920px - 250px) * 0.46)   [content-end heroIMG-start]
                                calc((1920px - 250px) * 0.54)   [heroIMG-end paddRight-start]
                                1fr                             [paddRight-end];
        }

    }
    &__heroIMG{
        justify-self: right;
        @media only screen and (max-width: 767px) {
            grid-column: content-start / content-end;
            width: 75vh;
            //margin-top: -124px;
            opacity: 0.15;
            position: absolute;
            z-index: -1;
            top: -85px;
            //justify-content: center;
            //width: 100vw;
            & img {
                width: 100%; 
            }
        }   
        @media only screen and (min-width: 768px) and (max-width: 1365px) {
            grid-column: content-start / content-end;
            height: calc(100% + 120px);
            //margin-top: -124px;
            opacity: 0.15;
            position: absolute;
            z-index: -1;
            top: -125px;
            //justify-content: center;
            //width: 100vw;
        }
        @media only screen and (min-width: 1366px) {
            //grid-column: heroIMG-start / heroIMG-end;
            height: calc(100% + 160px);
            margin-top: -160px;
            
        }                       
        @media only screen and (min-width: 1920px) {
            //grid-column: heroIMG-start / heroIMG-end;
            height: calc(100% + 180px);
            margin-top: -180px;
            justify-self: left;
        }
        & img {
            height: 100%; 
        }
    }
    &__title {   
        grid-column: content-start / content-end; //position regarding parent grid
        //grid-row: content-start / content-end;  //position regarding parent grid
        position: relative;
        text-align: left;
        align-self: center;
        @media only screen and (min-width: 768px) {
            padding-left: 30px;
        }
        @media only screen and (min-width: 1366px) {
            //margin-left: 40px;
            padding-left: 20px; //want to always have negative space
            //background-color: rgba(255,255,255,0.85);
            border-radius: 7px;
            padding-top: 35px;
            padding-bottom: 35px;
            padding-right: 20px;
        }                       
        @media only screen and (min-width: 1920px) {
            padding-left: 40px; //want to always have negative space
        }

        &--h1 {
            font-family: 'Montserrat', sans-serif;
            font-size: 38px;
            color: #F75E71;
            @media only screen and (max-width: 767px) {
                font-size: 32px;
                padding-bottom: 40px;
            }
            @media only screen and (min-width: 768px) {
                padding-bottom: 40px;
                text-align: center;
            }
            @media only screen and (min-width: 1366px) {
                padding-bottom: 40px;
            }                       
            @media only screen and (min-width: 1920px) {
                padding-bottom: 60px;
            }
        }
        &--p {
            font-family: 'Open Sans', sans-serif;
            font-size: 24px;
            color: #707070;
            @media only screen and (max-width: 767px) {
                font-size: 20px;
                //padding-bottom: 40px;
            }
            @media only screen and (min-width: 768px) {
                padding-bottom: 40px;
            }
            @media only screen and (min-width: 1366px) {
                padding-bottom: 40px;
            }                       
            @media only screen and (min-width: 1920px) {
                padding-bottom: 60px;
            }
        }
        &--buttonWrapper {
            display: flex;
            width: 100%;
            @media only screen and (max-width: 767px) {
                justify-content: start;
                flex-wrap: wrap;
                height: 200px;
                align-content: space-around;
            }
            @media only screen and (min-width: 768px) {
                justify-content: space-evenly;
            }
        }
        &--cta_button {
            @media only screen and (min-width: 768px) {
                margin-right: 0px;
            }
            @media only screen and (min-width: 1366px) {
                margin-right: 40px; //want to always have negative space
            }                       
            @media only screen and (min-width: 1920px) {
                margin-right: 90px; //want to always have negative space
            }
        }                                               
    }
}
_:-ms-lang(x), _:-webkit-full-screen, .heading__heroIMG { 
    width: 100%;
    //top: 50%;
    @media only screen and (max-width: 767px) {
        left: 50%;
        top: 50%;
        transform: translate(0%, -50%);
    }
    @media only screen and (min-width: 768px) and (max-width: 1365px) {
        left: 25%;
    }

    //left:  50%;
    //transform: translate(-50%, 0%);
}
.heading__divider {
    width: 100vw;
    position: relative;
    & img {
        width: 100%;
    }
}
// ---- BUTTON -------------
.gallery__button-hero,
.gallery__button-hero:link, 
.gallery__button-hero:visited {
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    padding: 13px 26px;
    width: 220px;
    @media only screen and (max-width: 767px) {
        font-size: 18px;
        padding: 13px 13px;
        width: auto;
    }
    position: relative;
    display: inline-block;
    text-align: center;
    background-image: linear-gradient(120deg, #F75C72 40%, #FB9126 100%);
    color: #FFF;
    cursor: pointer;
    /*changeforthe<button>element*/border: 3px solid #F75E71;
    border-radius: 50px;
    box-shadow: 5px 8px 16px rgba(0,0,0,0.2);
    transition: transform .2s,
                box-shadow .2s;
}
.gallery__button-hero:hover {
    transform: translateY(-8px);
}
.gallery__button-hero:active,
.gallery__button-hero:focus {
    /*outline: none;*/
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.5); 
}
////------------- Gallery button 5 
.gallery__button-5,
.gallery__button-5:link, 
.gallery__button-5:visited {
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    padding: 16px 32px;
    position: relative;
    display: inline-block;
    text-align: center;
    background-color: transparent;
    color: #F75E71;
    cursor: pointer;
    border: 3px solid #F75E71;
    border-radius: 50px;
    box-shadow: 5px 8px 16px rgba(0,0,0,0.2);
    background-image: linear-gradient(120deg, transparent 0%, transparent 50%, #F75C72 50%, #FB9126 95%);
    background-size: 240%;
    transition: transform .2s,
                background-position .5s,
                color .3s,
                box-shadow .2s;
}
.gallery__button-5:hover {
    transform: translateY(-8px);
    -webkit-transform: translateY(-8px);
    -moz-transform: translateY(-8px);
    -ms-transform: translateY(-8px);
    -o-transform: translateY(-8px);
}
.gallery__button-5:active,
.gallery__button-5:focus {
    /*outline: none;
    */transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.8);
    -webkit-transform: translateY(-1px);
    -moz-transform: translateY(-1px);
    -ms-transform: translateY(-1px);
    -o-transform: translateY(-1px);
}
.gallery__button-5:hover,
.gallery__button-5:active {
    
    background-position: 100%;
    color: white;
}

////------------- Gallery button 5 
.gallery__button-5-inverted,
.gallery__button-5-inverted:link, 
.gallery__button-5-inverted:visited {
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    padding: 16px 32px;
    position: relative;
    display: inline-block;
    text-align: center;
    background-color: transparent;
    // color: #F75E71;
    color: $color-white;
    cursor: pointer;
    border: 3px solid $color-primary;
    border-radius: 50px;
    box-shadow: 5px 8px 16px rgba(0,0,0,0.2);
    // background-image: linear-gradient(120deg, transparent 0%, transparent 50%, #F75C72 50%, #FB9126 95%);
    background-image: linear-gradient(120deg, #F75C72 0%, #FB9126 50%, transparent 50%, transparent 95%);
    background-size: 240%;
    transition: transform .2s,
                background-position .5s,
                color .3s,
                box-shadow .2s;
}
.gallery__button-5-inverted:hover {
    transform: translateY(-8px);
    -webkit-transform: translateY(-8px);
    -moz-transform: translateY(-8px);
    -ms-transform: translateY(-8px);
    -o-transform: translateY(-8px);
}
.gallery__button-5-inverted:active,
.gallery__button-5-inverted:focus {
    /*outline: none;
    */transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba(0,0,0,0.8);
    -webkit-transform: translateY(-1px);
    -moz-transform: translateY(-1px);
    -ms-transform: translateY(-1px);
    -o-transform: translateY(-1px);
}
.gallery__button-5-inverted:hover,
.gallery__button-5-inverted:active {
    
    background-position: 100%;
    color: $color-primary;
}
///// necessary for the pop up video
.tingle-demo {
    display: none; 
}  
.popup_video {
    display: block;
    margin: 0 auto; 
}  
.tingle-modal-box {
    background-color: rgba(255, 255, 255, 0) !important; 
}