.loading {
    &__wrapper {
        position: fixed;
        z-index: 10000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #FFF; 
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__svg { 
        width: 66vw;
        max-width: 100%;
        max-height: 100%;
        //transform: scaleX(-1); // was on original code. Not sure why it is needed.     :;
}
    &__fadeout {
        animation: fadeOut 0.3s;
        animation-fill-mode: forwards;
    }
}
.frame {
    visibility: hidden;
}

#wolf {
    fill: #f35740;
}
/*
#svg-animation {
    animation: loading-spinner 1s linear infinite;
} 
@keyframes loading-spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
*/
@keyframes fadeOut {
    100% {
        opacity: 0;
        //visibility: hidden;
        display: none;
        z-index: -100;
    }
}

