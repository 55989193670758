@use "../_0-pre-code/variables" as *;
.MSW-portfolio {
    &__mt {
        margin-top: 120px;
    }
    &__display{
        position: relative;
        width: $portfolio-display-width-xs;
        min-width: $portfolio-display-width-xs;
        max-width: $portfolio-display-width-xs;
        height: $portfolio-display-height-xs;
        // Small devices (landscape phones, 576px and up)
        @media (min-width: 576px) {
            width: $portfolio-display-width-md;
            min-width: $portfolio-display-width-md;
            max-width: $portfolio-display-width-md;
            height: $portfolio-display-height-md;
        }
        // Large devices (desktops, 992px and up)
        @media (min-width: 992px) {
            width: $portfolio-display-width-lg;
            min-width: $portfolio-display-width-lg;
            max-width: $portfolio-display-width-lg;
            height: $portfolio-display-height-lg;
        }
        // X-Large devices (large desktops, 1200px and up)
        @media (min-width: 1200px) {
            width: $portfolio-display-width-xl;
            min-width: $portfolio-display-width-xl;
            max-width: $portfolio-display-width-xl;
            height: $portfolio-display-height-xl;
        }
        &--svg {
            position: absolute;
            width: 100%;
            height: 100%;
        }
        &--img {
            position: relative;
            width: 100%;
            height: auto;
            top: $portfolio-image-top-xs;
            // Small devices (landscape phones, 576px and up)
            @media (min-width: 576px) {
                top: $portfolio-image-top-md;
            }
            // Large devices (desktops, 992px and up)
            @media (min-width: 992px) {
                top: $portfolio-image-top-lg;
            }
            // X-Large devices (large desktops, 1200px and up)
            @media (min-width: 1200px) {
                top: $portfolio-image-top-xl;
            }
        }
    }
    &__text{
        &--maxWidth{
            max-width: 98%;
            // Small devices (landscape phones, 576px and up)
            @media (min-width: 576px) {
                max-width: $portfolio-display-width-md;
            }  
        }
        &--h4{
            text-transform: none;
        }
    }
}


.portfolio {
    &__wrapper {
        //position: relative;
        height: 460px;
        width: 500px;
        // @media only screen and (min-width: 1366px) {
        //     height: 460px;
        //     width: calc((1366px - 280px) * 0.618);
        // }
        // @media only screen and (min-width: 1920px) {
        //     height: 600px;
        //     width: calc((1920px - 500px) * 0.618);
        // }
        
        // @media only screen and (max-width: 1365px) {
        //     height: 368px;
        //     width: 543px;
        //     margin: 0 auto;
        // }
        // @media only screen and (max-width: 767px) {
        //     height: 231px;
        //     width: 340px;
        //     margin: 0 auto;
        // }
    }
    &__svg {
        position: relative;
        top: 0;
        left: 0;
        height: 460px;
        width: 100%;
        // @media only screen and (min-width: 1366px) {
        //     height: 460px;
        //     width: 100%;
        // }
        // @media only screen and (min-width: 1920px) {
        //     height: 600px;
        //     width: 100%;
        // }
        // @media only screen and (max-width: 1365px) {
        //     height: 368px;
        //     width: 100%;
        // }
        // @media only screen and (max-width: 767px) {
        //     height: 231px;
        //     width: 100%
        // }
    }
    &__img {
        position: absolute;
        top: 98px;
        left: 0px;
        height: auto;
        width: 100%;
        // @media only screen and (min-width: 1366px) {
        //     top: 62px;
        //     left: 0px;
        //     height: 375px;
        // }
        // @media only screen and (min-width: 1920px) {
        //     top: 80px;
        //     left: 0px;
        //     height: 493px;
        // }
        // @media only screen and (max-width: 1365px) {
        //     top: 48px;
        //     left: 0px;
        //     height: 304px;
        // }
        // @media only screen and (max-width: 767px) {
        //     top: 30px;
        //     left: 0px;
        //     height: 192px;
        // }
        & img {
            position: relative;
            height: 100%;
            width: 100%; 
        }
    }
} 