@use "../_0-pre-code/variables" as *;
@use "../_0-pre-code/mixins" as *;

//---------------- Navigation ----------------------------
.MSW-nav__paddingTop {
    @media only screen and (max-width: 767px) {
        height: 20px;
    } 
    @media only screen and (min-width: 768px) {
        height: 30px;
    } 
    @media only screen and (min-width: 1366px) {
        height: 56px;
    } 
    
    //z-index: 1;
}
.MSW-navbar-toggler {
    display: none;
    top: 10px;
    height: 35px;
    width: 40px;
    // Small devices (landscape phones, 576px and up)
    @media (min-width: 576px) {
        top: 30px;
    }

    @media (max-width: 991.98px) {
        display: block;
        //height: 40px;
        //width: 40px;
        //width: 100vw;
        //justify-content: flex-end;
        position: absolute;
        // height: 40px;
        right: 30px;
        background-color: transparent;
        color: orangered;
        border: none;
        outline: none;
        padding: 0 5px;
        cursor: pointer;
    }
}
@media (max-width: 991.98px) {
    .MSW-navbar-toggler span,
    .MSW-navbar-toggler span::before,
    .MSW-navbar-toggler span::after {
    display: block;
    content: '';
    background-color: orangered;
    height: 4px;
    width: 24px;
    border-radius: 50px;
    //transform: translateX(10px);
    transition: all  ease-out 0.3s;
    }
    .MSW-navbar-toggler span::before{
        transform: translate3D(6px, -9px, 0);
    }
    .MSW-navbar-toggler span::after{
        transform: translate3D(6px, 5px, 0);
    }
    .MSW-navbar-toggler.open-navbar-toggler span {
        background-color: transparent;
    }
    .MSW-navbar-toggler.open-navbar-toggler span::before {
        transform: translateY(0px) rotate(45deg);
    }
    .MSW-navbar-toggler.open-navbar-toggler span::after {
        transform: translateY(-4px) rotate(-45deg);
    }
}
.MSW-nav {
    padding-top: 1px;
    position: sticky;
    top: -1px;
    z-index: 2;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 767px) {
        //padding-left: 20px;
        flex-wrap: wrap;
        padding: 0;
    } 
    @media only screen and (min-width: 768px) {
        padding-left: 30px;
    } 
    @media only screen and (min-width: 1366px) {
        padding-left: 70px;
    }
    @media only screen and (min-width: 1920px) {
        padding-left: 125px;
    }
    // &__logo{
    //     @media only screen and (max-width: 767px) {
    //         height: 57px;
    //         padding-left: 20px;
    //         padding-top: 5px;
    //     } 
    //     @media only screen and (min-width: 768px) {
    //         height: 90px;
    //     } 
    //     @media only screen and (min-width: 1366px) {
    //         height: 100px;
    //     }                       
    //     @media only screen and (min-width: 1920px) {
    //         height: 120px;
    //     }
    //     //width:250px;
    //     padding-right: 30px;
    //     & img {
    //         height: 100%;
    //     }
    // }
    &__ul {
        height: 100%; //make it the same height as the grid container
        list-style-type: none; //get rid of buttlet points
        display: flex; // to align the menu in 1 dimension 
        flex-wrap: wrap; //makes menu wrap or no wrap
        justify-content: left;
        align-items: center;
        align-content: center;

        @media only screen and (max-width: 767px) {
            //enables the hiding of the menu.
            max-height: 0vh;
            overflow: hidden;
            // style the menu
            flex-direction: column;
            width:100vw;
            justify-content: flex-start;
            //padding-top:40px;

             //width: 100%;
             //padding: 0;
             background-color: rgba(247,94,113, 0.2);
            // height: 100vh;

            transition: padding-top 0.3s ease-out;
        } 
        &.open{
            max-height: 100vh;
            height: 100vh;
            padding-top: 40px;
        }
    }
    &__a { 
        //height: 100%;
        font-family: 'Open Sans', sans-serif;
        font-weight: 300;
        font-size: 2rem;
        
        @media only screen and (max-width: 767px) {
            font-size: 28px;
            //padding: 0;
        } 
        letter-spacing: .3rem;
        //text-decoration: none;
        text-transform: uppercase;
        color: #707070;
        
        &:link,
        &:visited {
            display: inline-block;
            padding: 1rem 2rem;
            @include respond(medium-phone) {
                padding: 1rem 1rem;
            } 
            @include respond(large-phone) {
                padding: 1rem 1.2rem;
            } 
            @include respond(tablet) {
                padding: 1rem 1.5rem;
            }
            @include respond(screen-2k) {
                padding: 1rem 1.5rem;
            }
            color: #707070;
            text-decoration: none;
            text-transform: uppercase;
            background-image: linear-gradient(110deg, transparent 0%, transparent 50%, #F75E71 50%);
            background-size: 230%;
            transition: all .4s;
        }
        &:hover,
        &:active {
            background-position: 100%;
            color: white;
            //transform: translateX(1rem);
        }
    } 
}


.MSW-nav__logo {
    @media (min-width: 576px) {
        width: auto !important;
        height: 80px !important;
    }
    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
        width: 200px !important;
        height: auto !important;
    }
}