@use "../_0-pre-code/variables" as *;
@use "../_0-pre-code/mixins" as *;

.image-grid {
    height: 75vh;
    width: 100vw;
    display: grid;                         
    grid-template-columns:  1fr;
    grid-template-rows: 1fr;
    #whatWeDoIMG-bg {
        grid-row: 1 / -1;
        grid-column: 1 / -1;
        @include respond(small-phone) {
            @include whatWeDo_imgs_phone;
        } 
        @include respond(medium-phone) {
            @include whatWeDo_imgs_phone;
        } 
        @include respond(large-phone) {
            @include whatWeDo_imgs_phone;
        }
        @include respond(tablet) {
            background-image:$veil, url("/img/Tablet/what-we-do-1.jpg");
        }
        @include respond(screen-HD) {
            background-image:$veil, url("/img/Desktop/munchkin_softworks-website-what_we_do.jpg") ; 
        }
        @include respond(screen-2k) {
            background-image:$veil, url("/img/img-2k/what-we-do-1.jpg");
        }
        @include respond(screen-4k) {
            background-image:$veil, url("/img/img-4k/what-we-do-1.jpg");
        }
    }
}
.whatWeDo__contentWrapper {
    background-color: $color-background;
    padding-top: $section-padding-top;
    padding-bottom: $section-padding-bot;
    text-align: center;
}
.whatWeDo__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50rem, 1fr));
    @include respond(medium-phone) {
        grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr)); 
    }
    @include respond(large-phone) {
        grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr)); 
    }
    grid-row-gap: 6rem;
    padding-right: 1rem;
    @include respond(large-phone) {
        padding-right: 2rem;
    }
    @include respond(tablet) {
        padding-right: 3rem;
    }
    @include respond(screen-HD) {
        padding-right: 7rem;
    }
    @include respond(screen-2k) {
        padding-right: 12rem;
    }
    @include respond(screen-4k) {
        padding-right: 10rem;
    }
}
.whatWeDo__grid--cell { //grid inside 'whatWeDo__wrapper'
    height: 100%;
    width: 100%;
    @include respond(small-phone) {
        display: grid;
        grid-template-columns:  [icon-start]    0.8fr       [icon-end text-start]
                                                2.2fr       [text-end]; 
    }
    @include respond(medium-phone) {
        display: grid;
        grid-template-columns:  [icon-start]    1fr       [icon-end text-start]
                                                2fr       [text-end]; 
    }
    @include respond(large-phone) {
        display: grid;
        grid-template-columns:  [icon-start]    1fr       [icon-end text-start]
                                                2fr       [text-end]; 
    }
}
.whatWeDo__svg {
    fill: #55c57a;
    @media only screen and (min-width: 34.43em) { 
        float: left;
        padding-top: 1.5rem;
    };
    @include respond(small-phone) {
        height: 75%;
        width: 75%;
        justify-self: center;
        align-self: start;
    }
    @include respond(medium-phone) {
        height: 75%;
        width: 75%;
        justify-self: center;
        align-self: center;
    }
    @include respond(large-phone) {
        height: 75%;
        width: 75%;
        justify-self: center;
        align-self: center;
    }
}
.whatWeDo__textBlock {
    text-align: left;
    @media only screen and (min-width: 34.43em) { 
        overflow: hidden; //helps it so the text does not wrap around the wrapped elemnt
    };
}
.whatWeDo--h4 {
    padding-bottom: 1rem;
}