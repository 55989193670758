@use "../_0-pre-code/variables" as *;

.btn-primary {
    background-color: $color-primary;
    border-color: $color-primary;
}

.MSW-btn {
    &__shadows {
        filter: drop-shadow(5px 8px 16px rgba(0,0,0,0.2));
        text-align: center;
        transition: all 0.2s;
        &:hover{
            transform: translate3d(0, -0.5rem, 0);
        }
        &:active {
            transform: translate3d(0, 0rem, 0);
            filter: drop-shadow( 0 0.5rem 1rem rgba(0,0,0,0.8) );
            -webkit-transform: translate3d(0, 0rem, 0);
            -moz-transform: translate3d(0, 0rem, 0);
            -ms-transform: translate3d(0, 0rem, 0);
            -o-transform: translate3d(0, 0rem, 0);
        }
    }
    &__bg-border { //needs to be the background clip path (border)
        display: inline-block;
        height: 58px;
        width: 256px;
        padding-top: 3px;
        @media only screen and (max-width: 580px) {
            height: 34px;
            width: 150px;
            padding-top: 2px;
        }
        clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
        margin: 0 auto;
        &--header {
            background-color: rgba(0,31,42,0.55);
        }
        &--portfolio {
            background-color:black;
        }
    }
    &,
    &:link, 
    &:visited {
        text-decoration: none;
        //font-family: var(--font-family-main-text);
        font-family: sans-serif;  
        display: inline-block; //whithout this button covers other letters on title
        //background-image: linear-gradient(to bottom right, #000000, #2f2f2f);
        font-size: 20px;
        padding: 14px;
        width: 244px;
        clip-path: polygon(9% 0%, 100% 0%, 90.7% 100%, 0% 100%);
        cursor: pointer; //change for the <button> element
        @media only screen and (max-width: 580px) {
            font-size: 10px;
            padding: 10px 5px;
            width: 133px;
            clip-path: polygon(9.2% 0%, 100% 0%, 91% 100%, 0% 100%);
        }
    }
    &--header {
            color: white; 
            background-image: linear-gradient(113deg, transparent 0%, transparent 50%, white 50%);
            background-size: 220%;
            background-color: transparent;
            transition: all .5s;
            &:hover,
            &:active {
            background-position: 100%;
            color: black;
        }
    }
    &--portfolio {
        color: black; 
        background-color: white;
        background-image: linear-gradient(113deg, transparent 0%, transparent 50%, black 50%);
        background-size: 220%;
        transition: all .5s;
        &:hover,
        &:active {
        background-position: 100%;
        color: white;
        }
    }
    &--portfolio-inverted {
        color: black; 
        background-color: white;
        background-image: linear-gradient(113deg, transparent 0%, transparent 50%, black 50%);
        background-size: 220%;
        transition: all .5s;
        &:hover,
        &:active {
        background-position: 100%;
        color: white;
        }
    }
}