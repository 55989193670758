
//layout colors
$color-background:white;
$color-background-footer:#55c57a;
$footer-text-color: white;
$color-background-nav:white; 

//font families
$font-family-main-text: 'Open Sans', sans-serif;
//$font-family-main-h3: 'Montserrat', sans-serif;
$font-family-main-h3: 'Comfortaa', cursive; 
$font-family-main-h2: 'Comfortaa', cursive; 
$font-family-main-h1: 'Pacifico', cursive;
$font-family-main-a : 'Comfortaa', cursive;
$font-family-main-nav: 'Comfortaa', cursive;

//text colors
$color-primary: #F75C72;
$color-primary-light: #222222;
$color-primary-dark: #000000;
$color-white: #ffffff;


$color-text-white: #ffffff;
$color-grey-light-1: #8A8A88;
$color-grey-dark-1: #454545;
$color-grey-border: #2b2b28;

$icon-fill: #000000;
$icon-fill-hover: #55c57a;

//padding
$padding-bot-txt: 5rem;
$padding-bot-h3: 4rem;
$padding-container-top: 7rem;
$section-padding-top: 7rem;
$section-padding-bot: 7rem;
$padding-bot-h3--footer: 0.5rem;
$padding-bot-h4: 1.5rem;

//padding $ what we do
$padding-right-icon: 2rem;
//form menu borders
$menu-border-valid: #5bbad5;
$menu-border-invalid: #eb2f64;

//images can be updated based on mediaquerries!!! like if screen is small, then load smaller picture for phone. 
$background-img-header: url();
$background-img-whatWeDo: url(/img/Desktop/what-we-do-1.jpg);
$background-img-aboutUs: url();
$background-img-gallery: url();
$background-img-testimonials: url();
$background-txt-testimonials: url();
$background-img-findUs: url();
$background-map-findUs: url();
$background-img-contactUs: url(); 

$veil-header: linear-gradient(rgba(0, 0, 0, 0.4),  rgba(0, 0, 0, 0.4));
$veil-header-dark: linear-gradient(rgba(0, 0, 0, 0.6),  rgba(0, 0, 0, 0.6));
$veil: linear-gradient(to bottom, transparent 50%, rgba(0, 0, 0, 0.2) 75%, rgba(0, 0, 0, 0.5) 100%);


//gallery images
$gallery-img-size: 35rem;
$gallery-img-1: url("/img/common-img/item-1.jpg");
$gallery-img-2: url("/img/common-img/item-2.jpg");
$gallery-img-3: url("/img/common-img/item-3.jpg");
$gallery-img-4: url("/img/common-img/item-4.jpg");
$gallery-img-5: url("/img/common-img/item-5.jpg");
$gallery-img-6: url("/img/common-img/item-6.jpg");
$gallery-img-7: url("/img/common-img/item-7.jpg");
$gallery-img-8: url("/img/common-img/item-8.jpg");

//social media
$icon-color-default: #287842;
$icon-color-hover: #ffffff;

//////////////////////////////////////
//Bootstrap variables - only work is using bootstrap source sass files 
$grid-columns:      12;
$grid-gutter-width: 1.5rem;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
);

//portfolio display sizes
$portfolio-display-width-xl: 720px;
$portfolio-display-height-xl: 488px;
$portfolio-image-top-xl: 64px;

$portfolio-display-width-lg: 600px;
$portfolio-display-height-lg: 407px;
$portfolio-image-top-lg: 52px;

$portfolio-display-width-md: 435px;
$portfolio-display-height-md: 295px;
$portfolio-image-top-md: 39px;

$portfolio-display-width-xs: 300px;
$portfolio-display-height-xs: 204px;
$portfolio-image-top-xs: 26px;