:root{
    --veil-header: rgba(0, 0, 0, 0.65);
    --navBar-height: 80px;
    //OUR WORK Section - Shananegans
    --ourWork-section-width: 1366px;
    
} 
*,
*::before,
*::after { //without this every element has a default padding or margin
    box-sizing: inherit;
}
* {     
    margin: 0;
    padding: 0;
}
html { // standard practice to make elements render properly
    box-sizing: border-box;
    visibility: visible;
    background-color: transparent; // shows the website content and prevents FOUC
    font-size: 62.5%; //makes 1 rem = 10px; 
    
    //scroll-behavior: smooth;
    
}