@use "../_0-pre-code/variables" as *;
.MSW-certifications__box {
    text-decoration: none;
    width: 290px; 
    height: 220px; 
    border: 1px solid #e5e5e5; 
    border-radius: 3px;
    padding: 22px 0 0;
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20);
    text-align: center;
    transition: all 0.2s;
    &:hover{
        transform: translate3d(0, -0.5rem, 0);
        cursor: pointer;
    }
    &:active {
        transform: translate3d(0, 0rem, 0);
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20);
        -webkit-transform: translate3d(0, 0rem, 0);
        -moz-transform: translate3d(0, 0rem, 0);
        -ms-transform: translate3d(0, 0rem, 0);
        -o-transform: translate3d(0, 0rem, 0);
    }
}
.MSW-certifications__title {
    color: #333 !important;
    font-size: 16px !important;
    font-weight: 600;
}
.MSW-certifications__subtitle {
    color: #666 !important;
    font-size: 12px !important;
    line-height: 1.25;
}