/*#ourStoryIMG-bg {
    @include respond(small-phone) {
        @include about_imgs_phone; 
    } 
    @include respond(medium-phone) {
        @include about_imgs_phone;  
    }
    @include respond(large-phone) {
        @include about_imgs_phone;  
    }
    @include respond(tablet) {
        background-image: $veil, url("/img/Tablet/about-us.jpg");  
    }
    @include respond(screen-HD) {
        background-image: $veil, url("/img/Desktop/munchkin_softworks-website-about.jpg");   
    }
    @include respond(screen-2k) {
        background-image: $veil, url("/img/img-2k/about-us.jpg");  
    }
    @include respond(screen-4k) {
        background-image: $veil, url("/img/img-4k/about-us.jpg");  
    }
}*/
#ourStory-ID {

    padding-bottom: 60px;
    @media only screen and (max-width: 767px) {
        padding-bottom: 30px;
    }
}
.ourStory__heading {
}
.ourStory {
    &__contentWrapper{
        //text-align: center;
        display: grid;
        @media only screen and (max-width: 767px) {
            grid-template-columns: 1fr calc(360px - 40px) 1fr;
        }
        @media only screen and (min-width: 768px) {
            grid-template-columns: 1fr calc((768px - 60px) * 0.618) calc((768px - 60px) * 0.382) 1fr;
        }
        @media only screen and (min-width: 1366px) {
            grid-template-columns: 1fr calc((1366px - 140px) * 0.618) calc((1366px - 140px) * 0.382) 1fr;
        }                       
        @media only screen and (min-width: 1920px) {
            grid-template-columns: 1fr calc((1920px - 250px) * 0.618) calc((1920px - 250px) * 0.382) 1fr;
        }
        grid-template-rows: min-content min-content;
    }
    &__heading {
        grid-column: 2 / 3;
        grid-row: 1 / 2;

        @media only screen and (min-width: 1366px) {
            padding-left: 70px;
        }                       
        @media only screen and (min-width: 1920px) {
            padding-left: 125px;
        }
    }
    &__content {
        font-family: 'Open Sans', sans-serif;
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        color: #707070;
        @media only screen and (max-width: 767px) {
            font-size: 22px;
        }
        @media only screen and (min-width: 768px) {
            font-size: 22px;
        }
        @media only screen and (min-width: 1366px) {
            padding-left: 70px;
            font-size: 24px;
        }                       
        @media only screen and (min-width: 1920px) {
            padding-left: 125px;
            font-size: 24px;
        }
        //padding-bottom: 60px;
    }
    &__companyLogos{
            position: absolute;
            align-self: center;
            font-size: 22px;
        @media only screen and (min-width:  992px) {
            position: relative;
            font-size: 22px;
            padding-left: 20px;
        }
        text-align: center;
        &--IMG {
                width: 80%;
                height: auto;
                opacity: 0.20;
                //position: relative;
                z-index: -1;
            @media only screen and (min-width:  992px) {
                width: 100%;
                height: auto;
                opacity: 1;
            }
        }
    }
}
