.credits {
    &__button {
        border: none;
        box-sizing: border-box; // this makes it so that width 100% + padding does not go over the 100% width of the parent div
        outline: none;
        cursor: pointer;
        width: 100%;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        padding-left: 3rem;
        text-align: left;
        font-family: 'Montserrat', sans-serif;
        //font-family: var(--font-family-main-h4);     
        text-transform: uppercase;
        letter-spacing: 4px;
        font-size: 2.5rem;
        background-color:  #B43B4A;
        color: white;
        box-shadow: 0px 4px 10px 0px rgba(0,0,0, 0.3);
        &:after {
            content: '\002B';
            color: white;
            font-weight: bold;
            float: right;
            padding-right: 3rem;
            
        }
        transition: color .2s, 
                    background-color .25s;
    }
    &__active,
    &__button:hover {
        background-color: #fff;
        color: #F75E71;
    }
    &__active:after {
        content: "\2212";
        color: #F75E71;
    }
    &__content {
        //padding-top: 30px;
        padding-left: 3rem;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
        background-color: #FFF;
        color: #F75E71;
        & h4 {
            font-size: 2.5rem;
            padding-bottom: 2rem;
        }
        & span {
            font-family: 'Open Sans', sans-serif;
        }
        & p {
            padding-bottom: 4rem;
        }
    } 
}

