// the resolution for a 2k Ultra wide monitor is 3440 x 1440
// the resolution for a 4k monitor is 3840 x 2160
// the resolution for a 1080p monitor is 1920 x 1080
// the approach i will take is the assumption of the website designed for a height and width of a 1080p mnitor, then proceed with the min-height and min-width paramenters which mean at least or greater the pixel specified. then a max width parameter to go smaller until phone size is reached. in the future I think a mobile first approach and then git bigger progresively with min-width and min-height is more logical and preferable. 
    //res range     devices affected 03/2018
    //0-350         x2
    //350-400       x12
    //400-550       x15
    //550-1250      x9
    //1251-1950     HD 720p and HD1080p (1280-1920pixels)
    //1950-2600     up to 2k (2k is 2560pixels)
    //2600 and up   4k (3840pixels)
@mixin respond($breakpoint) {
    @if $breakpoint == small-phone {
        @media only screen and (min-width: 1em) and (max-width: 21.875em) { @content }; //min-width:16px max-width:350px;
    }
    @if $breakpoint == medium-phone {
        @media only screen and (min-width: 21.876em) and (max-width: 25em) { @content }; //min-width:351px max-width:400px;
    }
    @if $breakpoint == large-phone {
        @media only screen and (min-width: 25.01em) and (max-width: 34.375em) { @content }; //min-width:401px max-width:550px; 
    }
    @if $breakpoint == tablet {
        @media only screen and (min-width: 34.43em) and (max-width: 85.25em) { @content }; //min-width:550.24px max-width:1364px;
    }
    @if $breakpoint == screen-HD {
        @media only screen and (min-width: 85.313em) and (max-width: 121.88em) { @content }; //min-width:1365px max-width:1950px;
    }
    @if $breakpoint == screen-2k {
        @media only screen and (min-width: 121.94em) and (max-width: 162.5em) { @content }; //min-width:1951px max-width:2600px;
    }
    @if $breakpoint == screen-4k {
        @media only screen and (min-width: 162.56em) { @content }; //min-width: 2601px;
    }
}
///////////////Displaying Picture for Phones Mixins
@mixin header_imgs_phone {
    --background-img-header: url(/img/img-phone-1dppx/munchkin_softworks-website-cover.jpg); 
    @media only screen and (min-resolution: 2dppx) and (max-resolution: 2.99dppx) { 
        --background-img-header: url(/img/img-phone-2dppx/munchkin_softworks-website-cover.jpg); 
    };
    @media only screen and (min-resolution: 3dppx) { 
        --background-img-header: url(/img/img-phone-3dppx/munchkin_softworks-website-cover.jpg); 
    };
}
@mixin whatWeDo_imgs_phone {
    --background-img-whatWeDo: url(/img/img-phone-1dppx/munchkin_softworks-website-what_we_do.jpg); 
    @media only screen and (min-resolution: 2dppx) and (max-resolution: 2.99dppx) { 
        --background-img-whatWeDo: url(/img/img-phone-2dppx/munchkin_softworks-website-what_we_do.jpg); 
    };
    @media only screen and (min-resolution: 3dppx) { 
        --background-img-whatWeDo: url(/img/img-phone-3dppx/munchkin_softworks-website-what_we_do.jpg); 
    };
}
@mixin about_imgs_phone {
    --background-img-aboutUs: url(/img/img-phone-1dppx/munchkin_softworks-website-about.jpg); 
    @media only screen and (min-resolution: 2dppx) and (max-resolution: 2.99dppx) { 
        --background-img-aboutUs: url(/img/img-phone-2dppx/munchkin_softworks-website-about.jpg); 
    };
    @media only screen and (min-resolution: 3dppx) { 
        --background-img-aboutUs: url(/img/img-phone-3dppx/munchkin_softworks-website-about.jpg); 
    };
}
@mixin gallery_imgs_phone {
    --background-img-gallery: url(/img/img-phone-1dppx/munchkin_softworks-website-our_work.jpg); 
    @media only screen and (min-resolution: 2dppx) and (max-resolution: 2.99dppx) { 
        --background-img-gallery: url(/img/img-phone-2dppx/munchkin_softworks-website-our_work.jpg); 
    };
    @media only screen and (min-resolution: 3dppx) { 
        --background-img-gallery: url(/img/img-phone-3dppx/munchkin_softworks-website-our_work.jpg); 
    };
}
@mixin testimonials_imgs_phone {
    --background-img-testimonials: url(/img/img-phone-1dppx/testimonials.jpg); 
    @media only screen and (min-resolution: 2dppx) and (max-resolution: 2.99dppx) { 
        --background-img-testimonials: url(/img/img-phone-2dppx/testimonials.jpg); 
    };
    @media only screen and (min-resolution: 3dppx) { 
        --background-img-testimonials: url(/img/img-phone-3dppx/testimonials.jpg); 
    };
}
@mixin testimonialsTXT_imgs_phone {
    --background-txt-testimonials: url(../img/img-phone-1dppx/testimonial-txt-bg.jpg); 
    @media only screen and (min-resolution: 2dppx) and (max-resolution: 2.99dppx) { 
        --background-txt-testimonials: url(../img/img-phone-2dppx/testimonial-txt-bg.jpg); 
    };
    @media only screen and (min-resolution: 3dppx) { 
        --background-txt-testimonials: url(../img/img-phone-3dppx/testimonial-txt-bg.jpg); 
    };
}
@mixin findUs_imgs_phone {
    --background-img-findUs: url(../img/img-phone-1dppx/find-us.jpg); 
    @media only screen and (min-resolution: 2dppx) and (max-resolution: 2.99dppx) { 
        --background-img-findUs: url(../img/img-phone-2dppx/find-us.jpg); 
    };
    @media only screen and (min-resolution: 3dppx) { 
        --background-img-findUs: url(../img/img-phone-3dppx/find-us.jpg); 
    };
}
@mixin findUsMAP_imgs_phone {
    --background-map-findUs: url(../img/img-phone-1dppx/restaurant-front-hover.jpg); 
    @media only screen and (min-resolution: 2dppx) and (max-resolution: 2.99dppx) { 
        --background-map-findUs: url(../img/img-phone-2dppx/restaurant-front-hover.jpg); 
    };
    @media only screen and (min-resolution: 3dppx) { 
        --background-map-findUs: url(../img/img-phone-3dppx/restaurant-front-hover.jpg); 
    };
}
@mixin contactUs_imgs_phone {
    --background-img-contactUs: url(/img/img-phone-1dppx/munchkin_softworks-website-contact_us.jpg); 
    @media only screen and (min-resolution: 2dppx) and (max-resolution: 2.99dppx) { 
        --background-img-contactUs: url(/img/img-phone-2dppx/munchkin_softworks-website-contact_us.jpg); 
    };
    @media only screen and (min-resolution: 3dppx) { 
        --background-img-contactUs: url(/img/img-phone-3dppx/munchkin_softworks-website-contact_us.jpg); 
    };
}

