@use "../_0-pre-code/variables" as *;
// @use "../_0-pre-code/mixins" as *;

.MSW-footerBTN {
    border: none;
    background-color: transparent;
    color: $color-text-white;
    text-decoration: underline;
    font-family: $font-family-main-text;
    font-size: 12px;
}

.footer {
    background-color: #F75E71;
    padding: 5rem 0;
    grid-column: content-start / content-end;
    grid-row: footer-start / footer-end;
    color: white;
    &__textBox {
        align-self: center;
        justify-self: center;
        text-align: center;
        & h4,
        & p {
            color: $footer-text-color;
        }
    }
    &__copyright {
        font-family: 'Open Sans', sans-serif;
        color: $footer-text-color;
        font-size: 16px;
        text-align: center;
        transition: color 0.3s ease-in;
        padding-top: 50px;
        &:hover {
            color: white;
        } 
    }
    &__p {
    }
    .test{
    }
    &__socialMedia {
        display: flex;
        align-content: center;
        justify-content: space-evenly;
        padding-bottom: 3rem;
        &--icon {

            width: 5rem;
            height: 5rem;
            overflow: hidden;
            fill:$icon-color-default;
            transition: all 0.3s;
        }
        &--icon:hover {
            fill:$icon-color-hover;
            cursor: pointer;
        } 
    }
    &__ThankYou {
        font-family: 'Pacifico', cursive;
        font-weight: 100;
        padding-bottom: 2rem;
        font-size: 7rem;
        @media only screen and (max-width: 767px) {
            font-size: 48px;
        }
        text-align: center;
        color: $footer-text-color;
        transition: color 0.3s ease-in;
        &:hover {
            color:  white; 
        }
    }
}
