.contact__wrapper {
    position: relative;
    display: grid;
    @media only screen and (max-width: 767px) {
        grid-template-columns: 1fr calc(360px - 40px) 1fr;
    }
    @media only screen and (min-width: 768px) and (max-width: 1365px) {
        grid-template-columns: 1fr calc(768px - 60px) 1fr;
    }
    @media only screen and (min-width: 1366px) {
        grid-template-columns: 1fr calc(1366px - 140px) 1fr;
    }
    @media only screen and (min-width: 1920px) {
        grid-template-columns: 1fr calc(1920px - 250px) 1fr;
    }
    //: hidden;

}
.mail__hover {
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
}
.mail__hover:hover {
    text-decoration: none;
    color: #F75E71;
}
.contact {
    &__text {
        grid-column: 2 / 3;
        @media only screen and (max-width: 767px) {
            padding-bottom: 60px;
            padding-left: 0px;
            padding-right: 0px;
        }
        @media only screen and (min-width: 768px) and (max-width: 1365px) {
            padding-bottom: 60px;
            padding-left: 30px;
            padding-right: 30px;
        }
        @media only screen and (min-width: 1366px) {
            padding-left: 70px;
            padding-bottom: 80px;
        }
        @media only screen and (min-width: 1920px) {
            padding-left: 125px;
            padding-bottom: 125px;
        }
    }
    &__bg {
        grid-column: -1 / -2;
        position: absolute;

        // justify-self: end;
        // align-self: end;
        @media only screen and (max-width: 767px) {
            height: 500px;
            opacity: 0.15;
        }
        @media only screen and (min-width: 768px) and (max-width: 1365px) {
            height: 600px;
            opacity: 0.15;
        }
        @media only screen and (min-width: 1366px) {
            height: 700px;
        }
        @media only screen and (min-width: 1920px) {
            height: 1000px;
        }

        bottom: 0;
        right: 0;

        
        z-index: -1;
        & img {
            height: 100%;
        }
    }
    &__group--socialMedia {
        display: flex;
        justify-content: space-between;
        width: 50%;
        padding-top: 30px;
        @media only screen and (max-width: 767px) {
            width: 100%;
        }
        @media only screen and (min-width: 768px) and (max-width: 1365px) {
            width: 100%;
        }
        @media only screen and (min-width: 1365px) {
            width: 80%;
        }
        
    }
    &__group {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-top: 30px;
        padding-bottom: 30px;
        &--svg {
            height: 75px;
            width: 75px;
            @media only screen and (max-width: 767px) {
                height: 50px;
                width: 50px;
            }
            @media only screen and (min-width: 768px) and (max-width: 1365px) {
                height: 65px;
                width: 65px;
            }
        }
        &--svg-hover{
            height: 100px;
            width: 100px;
            @media only screen and (max-width: 767px) {
                height: 50px;
                width: 50px;
            }
            @media only screen and (min-width: 768px) and (max-width: 1365px) {
                height: 75px;
                width: 75px;
            }
            fill: #F75E71;
            transition: all 0.3s ease-out;
            -webkit-transition: all 0.3s ease-out;
            -moz-transition: all 0.3s ease-out;
            -ms-transition: all 0.3s ease-out;
            -o-transition: all 0.3s ease-out;
}
        &--svg-hover:hover {
            fill: #fc2b82;
            cursor: pointer;
        }
        &--p {
            padding-left: 30px;
            @media only screen and (max-width: 767px) {
                font-size: 16px;
            }
        }
    }
    
}
_:-ms-lang(x), _:-webkit-full-screen, .contact__bg 
{ 
    width: 800px;
    left: calc(100vw - 800px);

}
