.MSF-Tech {
    &__container {
        width: 200px; 
        height: 200px; 
        border: 1px solid #f75e71;
    }
    &__svg {
        width:75px;
        height:75px;
    }
    &__containerEmpty{
        width: 200px; 
        height: 200px; 
        background-color: transparent;
        border: none;
    }
}